<template>
  <div>
    <div>
      <h2 class="app-heading mb-4">Widget Directory</h2>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 lg:w-3/4">
      <div class="flex flex-wrap justify-start">
          <button 
            v-for="(category, index) in widgetCategories" 
            :key="index" 
            @click="filterCategory(category.id)" 
            type="button" 
            class="mr-2 mt-2 vs-component vs-button vs-button-primary"
            :class= "{'vs-button-filled': activeCategoryId === category.id, 'vs-button-border': activeCategoryId !== category.id}">
            <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
            <span class="vs-button-text vs-button--text">{{category.name}}</span>
            <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
          </button>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2 lg:w-1/4 lg:pl-0 mb-4">
        <vs-input icon-pack="feather" icon="icon-search" placeholder="Search" v-model="searchText" @input="activeCategoryId=0" class="w-full mt-2"/>
      </div>
    </div>
    <div class="vx-row mt-4 match-height">
      <div v-for="(ourWidget, index) in ourWidgetList" :key="index" class="vx-col w-full md:w-1/2 lg:w-1/4 mt-4">
        <application-widget-card :widget="ourWidget" :applicationId="applicationId"/>
      </div>
    </div>
  </div>
</template>

<script>

import applicationWidgetStore from './applicationWidgetStore.js'
import ApplicationWidgetCard from './ApplicationWidgetCard.vue'

export default {
  props: {
    applicationId: { required: true }
  },
  components: {
    ApplicationWidgetCard
  },
  data () {
    return {
      activeCategoryId: 0,
      searchText: null
    }
  },
  computed: {
    ourWidgetList () {
      if (this.searchText) {
        const lodash = this.$_
        const searchText = this.searchText.toLowerCase()
        return this.$_.filter(
          applicationWidgetStore.state.allWidgets, widget => {
            return lodash.includes(widget.name && widget.name.toLowerCase(), searchText) 
              || lodash.includes(widget.description && widget.description.toLowerCase(), searchText)
          }
        )
      }

      if (this.activeCategoryId === 0) {
        return applicationWidgetStore.state.allWidgets
      }
      else {
        const activeCategory = this.$_.find(applicationWidgetStore.state.widgetCategories, x => x.id == this.activeCategoryId)
        return this.$_.filter(
          applicationWidgetStore.state.allWidgets,
          w => this.$_.includes(activeCategory.widgetIds, w.widgetId))
      }
    },
    allWidgetList() {
   
    },
    widgetCategories () {
      return [{id: 0, name: 'All Widgets' }, ...applicationWidgetStore.state.widgetCategories]
    }
  },
  watch: {
    applicationId (newVal, oldVal) {
      // Switching application ids does not re-mount this component
      this.$vs.loading()
      this.$store.dispatch('applicationWidgetStore/fetchAllWidgets', { applicationId: this.applicationId })
      .then(response => {
          
      })
      .catch(error => console.error(error))
      .finally(() => this.$vs.loading.close())  
    }
  },
  mounted () {
    this.$vs.loading()
    this.$store.dispatch('applicationWidgetStore/fetchAllWidgets', { applicationId: this.applicationId })
    .then(response => {
        
    })
    .catch(error => console.error(error))
    .finally(() => this.$vs.loading.close())  
  },
  methods: {
    dummyMethod() {
    },
    filterCategory (categoryId) {
      this.searchText = null
      this.activeCategoryId = categoryId
    }
  }
}
</script>

<style scoped lang="scss">
.category-button-inactive {
  background: transparent !important;
}
.app-heading {
  color: #636363
}
</style>